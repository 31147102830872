.App {
    /* text-align: center; */
    height: 100vh;
}

/* .dx-pivotgrid-area.dx-pivotgrid-horizontal-headers td, .dx-pivotgrid .dx-pivotgrid-area.dx-pivotgrid-vertical-headers td  {
    color: steelblue !important;
    font-weight: 600;
} */

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.dx-filterbuilder-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Radio-Label {
    --radio-font-size: 12px;
}

.dx-popup-title.dx-toolbar {
    background: linear-gradient(90deg, #0a3a62, skyblue);
    /* border-top-right-radius: 5px; */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: whitesmoke;
}

.dx-texteditor.dx-state-disabled .dx-dropdowneditor-icon, .dx-texteditor.dx-state-disabled .dx-lookup-arrow, .dx-texteditor.dx-state-disabled .dx-lookup-field, .dx-texteditor.dx-state-disabled .dx-placeholder::before, .dx-texteditor.dx-state-disabled .dx-texteditor-input, .dx-texteditor.dx-state-disabled .dx-texteditor-label {
    color: #e1e1e1 !important;
}

.dx-dropdowneditor-button .dx-button-content {
    margin-left: -4px;
    border-left: 1px #e9e9e9 groove;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.dx-texteditor
.dx-texteditor-buttons-container:last-child>.dx-button:last-child, .dx-texteditor
.dx-texteditor-buttons-container:last-child>.dx-clear-button-area:last-child {
    margin-right: 0;
    border-left: 1px #e9e9e9 groove;
}

.dx-button.dx-button-default {
    width: 80px;
}

.dx-tab.dx-tab-selected {
    background-color: #e5edff6b;
    border-top: 1px #5487ff83 solid;
    border-right: 1px #5487ff83 solid;
    border-left: 1px #5487ff83 solid;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.dx-tab .dx-tab-text {
    font-size: 12px;
}
